*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

@font-face {
  src: url('./assets/fonts/Material-Icons-Outline.ttf');
  font-family: 'Material Icons Outline';
}

.MuiPickersBasePicker-container {
  padding: 6px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1100px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.page {
  padding-top: 40px;
  padding-bottom: 80px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
